import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import {
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useGetPurchaseListQuery } from "@js/apps/invoices/api";
import {
  Section,
  SectionSubtitleAlignCenterAction,
} from "@js/apps/settings/forms/helpers";

interface Invoice {
  id: number;
  created: string;
  name: string;
  description: string;
}

const BillingSection: React.FC = () => {
  // const isMobile = useMediaQuery("sm");

  const page_size: number = 5;
  const [invoicesParams, setInvoicesParams] = useState({ page: 1, page_size });
  const [invoicesData, setInvoicesData] = useState<Invoice[]>([]);

  const { data: invoices, isLoading: isLoadingInvoice } =
    useGetPurchaseListQuery(invoicesParams);

  useEffect(() => {
    if (invoices) {
      const invocesArr = invoices?.results ? invoices.results : [];
      const data = [...invocesArr];
      const updatedInvoices = [...invoicesData, ...data].filter(
        (invoice, index, self) =>
          index === self.findIndex((i) => i.id === invoice.id),
      );
      setInvoicesData(updatedInvoices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices]);

  return (
    <>
      <Section title="Billing history">
        {invoicesData?.length ? (
          invoicesData?.map((item: Invoice) => (
            <Box key={item?.id}>
              <SectionSubtitleAlignCenterAction
                title={`${item.name} ${item?.name?.includes("Insights") ? "- 20 BTRST" : "- 39 BTRST"}`}
                description={dayjs(item?.created).format("MMMM D, YYYY")}
                action={null}
                divider
              />
            </Box>
          ))
        ) : (
          <Box>
            <Typography
              variant="label"
              size="large"
              component="h4"
              fontWeight={500}
            >
              You have no billing history
            </Typography>
            <Typography
              variant="label"
              size="medium"
              component="h4"
              fontWeight={400}
            >
              Once you purchase an upgrade, they will appear here.
            </Typography>
          </Box>
        )}

        {isLoadingInvoice && (
          <Box display={"flex"} justifyContent={"center"}>
            <Loader />
          </Box>
        )}
        {invoicesData.length < invoices?.count &&
          invoicesParams.page * page_size >= invoicesData.length && (
            <Box display={"flex"} justifyContent={"center"}>
              <Button
                onClick={() =>
                  setInvoicesParams({
                    ...invoicesParams,
                    page: invoicesParams.page + 1,
                  })
                }
                variant="secondary"
                shape="squared"
              >
                Load more
              </Button>
            </Box>
          )}
      </Section>
    </>
  );
};

export default BillingSection;
