import { type FC } from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { Emoji } from "@js/components/emoji";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";
import type { AppDispatch } from "@js/store";

export const InsightsSuccessModal: FC = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery("sm");

  return (
    <Box
      id="insight-success-modal"
      width={isMobile ? "90vw" : "629px"}
      maxWidth="629px"
      height="100%"
      padding={isMobile ? 2 : 4}
      boxSizing="border-box"
      display="flex"
      gap={2}
      flexDirection="column"
    >
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton
          variant="primary"
          size="x-small"
          aria-label="Close location mismatch modal"
          onClick={() => ModalInstance.close()}
          sx={{ border: "solid white 0.5rem", margin: "-1rem -1rem 0 0" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        display="flex"
        borderRadius={2}
        textAlign="center"
        sx={{
          background: "var(--medium-green)",
          "& img": {
            maxWidth: "100%",
          },
          margin: "-3rem 0 1rem 0",
        }}
      >
        <img
          src={`${SETTINGS.STATIC_URL}jobs/purchase-insight-success.svg`}
          width="100%"
          height="auto"
          alt="job-insight-image"
        />
      </Box>

      <Box display="flex" alignItems="baseline" gap={1}>
        <Typography
          component="h6"
          variant="title"
          size="small"
          fontWeight={500}
        >
          Application Insights is active
        </Typography>
        <Emoji size="1.625rem" emoji="🙌" />
      </Box>

      <Box>
        <Typography component="p" variant="paragraph" size="large">
          Find your perfect match and get personalized feedback for 30 days.
          View your purchase history{" "}
          <RouterLink
            to="/settings/purchases/"
            onClick={() => {
              dispatch(fetchCurrentUser());
              ModalInstance.close();
            }}
          >
            here.
          </RouterLink>
        </Typography>
      </Box>

      <Box textAlign="end" marginTop={2}>
        <Button
          variant="primary"
          shape="squared"
          RouterLink={RouterLink}
          to="jobs"
          onClick={() => {
            dispatch(fetchCurrentUser());
            ModalInstance.close();
          }}
        >
          Browse jobs
        </Button>
      </Box>
    </Box>
  );
};

export const openSuccessPurchaseModal = (dispatch: AppDispatch): void => {
  ModalInstance.open({
    padding: false,
    keepOnBackdropClick: true,
    children: <InsightsSuccessModal />,
  });

  const observer = new MutationObserver(() => {
    const modal = document.querySelector("#insight-success-modal");
    if (modal) {
      const parent = modal?.parentNode?.querySelector("button");
      parent?.addEventListener("click", function () {
        ModalInstance.close();
        dispatch(fetchCurrentUser());
      });
      observer.disconnect();
    }
  });
  observer.observe(document.body, { childList: true, subtree: true });
};
